<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="{ ...attrs, ...$attrs }"
        color="primary"
        small
        :text="!reportStore.showGlobalFilters"
        v-on="on"
        @click="
          () => (reportStore.showGlobalFilters = !reportStore.showGlobalFilters)
        "
      >
        <v-icon class="mr-2" x-small>
          {{
            reportStore.showGlobalFilters
              ? "fa-filter"
              : "fa-filter-circle-xmark"
          }}
        </v-icon>
        <span>Filters</span>
      </v-btn>
    </template>
    {{ `${reportStore.showGlobalFilters ? "Hide Filters" : "Show Filters"}` }}
  </v-tooltip>
</template>

<script>
import Vue from "vue"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"

export default Vue.extend({
  name: "ReportGlobalFiltersBtn",
  computed: {
    ...mapStores(useReportStore),
  },
})
</script>
