<template>
  <v-text-field
    v-model="model"
    dense
    hide-details
    placeholder="Search"
    v-bind="$attrs"
    @keyup="handleKeyUp"
    v-on="$listeners"
  >
    <template v-if="showToolTip" #prepend>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon small class="grey--text mt-1" v-on="on">
            far fa-question-circle
          </v-icon>
        </template>
        {{ toolTipText }}
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
import { camelizeKeys } from "humps"

export default {
  name: "TextFieldSearchFilter",
  props: {
    fieldName: {
      type: String,
      default: "",
      mandatory: true,
    },
  },
  data() {
    return {
      model: "",
      showToolTip: this.$attrs?.showToolTip,
      toolTipText: this.$attrs?.toolTipText,
    }
  },
  watch: {
    "$route.query": {
      handler() {
        this.updateFilterField()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    updateFilterField() {
      this.model = camelizeKeys(this.$route.query)[this.fieldName]
    },
    handleKeyUp() {
      this.$emit("search", { [this.fieldName]: this.model })
    },
  },
}
</script>
