<template>
  <div class="e-flex e-flex-wrap e-items-center my-2 e-gap-2">
    <div class="subtitle-1 e-text-gray-600 font-weight-medium">Filters</div>
    <div
      v-for="(field, key) in globalFilterFields"
      :key="key"
      :style="{ width: getWidth(field.width) }"
    >
      <component
        :is="field.component"
        v-bind="field.attributes"
        :field-name="field.name || key"
        outlined
        dense
        hide-details
        v-on="field.listeners"
        @search="
          (search) =>
            field.offline ? handleOfflineSearch(search) : handleSearch(search)
        "
      />
    </div>
  </div>
</template>

<script>
import { debounce } from "@evercam/shared/utils"
import TextFieldSearchFilter from "@/components/searchFilters/TextFieldSearchFilter"
import SelectSearchFilter from "@/components/searchFilters/SelectSearchFilter"
import DatePickerSearchFilter from "@/components/searchFilters/DatePickerSearchFilter"
import DatePickerRangeSearchFilter from "@/components/searchFilters/DatePickerRangeSearchFilter"
import SelectDatePickerRangeSearchFilter from "@/components/searchFilters/SelectDatePickerRangeSearchFilter"
import CountFieldSearchFilter from "@/components/searchFilters/CountFieldSearchFilter"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"

export default {
  components: {
    TextFieldSearchFilter,
    SelectSearchFilter,
    DatePickerSearchFilter,
    DatePickerRangeSearchFilter,
    SelectDatePickerRangeSearchFilter,
    CountFieldSearchFilter,
  },
  props: {
    filterFields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      searchParams: {},
    }
  },
  computed: {
    ...mapStores(useReportStore),
    fields() {
      return this.filterFields
    },
    globalFilterFields() {
      return Object.fromEntries(
        Object.entries(this.filterFields).filter(
          ([_, field]) => field.isGlobalFilter
        )
      )
    },
  },
  created() {
    this.reportStore.offlineSearchParams = Object.fromEntries(
      Object.entries(this.filterFields)
        .filter(([_, field]) => field.offline)
        .map(([field, { attributes }]) => [field, attributes?.value])
        .filter(
          ([_, value]) =>
            (Array.isArray(value) && !!value?.length) ||
            !["", null, undefined].includes(value)
        )
    )
  },
  mounted() {
    this.searchParams = this.reportStore.searchFilters
  },
  methods: {
    getWidth(width) {
      return typeof width === "number" ? `${width}px` : width || "auto"
    },
    handleSearch(change) {
      this.searchParams = {
        ...this.searchParams,
        ...change,
      }
      this.fireFilter(this)
    },
    handleOfflineSearch: debounce(function (search) {
      this.reportStore.offlineSearchParams = {
        ...this.reportStore.offlineSearchParams,
        ...search,
      }
    }, 200),
    fireFilter: debounce(function (self) {
      this.$emit("update-search-filters", self.searchParams)
    }, 500),
  },
}
</script>
