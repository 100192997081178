<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="{ ...attrs, ...$attrs }"
        color="primary"
        small
        :text="!bookmarkStore.isBookmarksDisplayed"
        v-on="on"
        @click="
          () =>
            (bookmarkStore.isBookmarksDisplayed =
              !bookmarkStore.isBookmarksDisplayed)
        "
      >
        <v-icon class="no-hover-effect mr-2" x-small>
          {{ bookmarkStore.isBookmarksDisplayed ? "fa-list" : "fa-list-alt" }}
        </v-icon>
        <span>Bookmarks</span>
      </v-btn>
    </template>
    {{
      `${
        bookmarkStore.isBookmarksDisplayed ? "Hide Bookmarks" : "Show Bookmarks"
      }`
    }}
  </v-tooltip>
</template>

<script lang="ts">
import Vue from "vue"
import { mapStores } from "pinia"
import { useBookmarksStore } from "@/stores/bookmarks"

export default Vue.extend({
  name: "ToggleBookmarkDisplay",

  computed: {
    ...mapStores(useBookmarksStore),
  },
})
</script>
